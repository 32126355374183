import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { JourneyComponent } from './journey/journey.component';
import { AirComponent } from './air/air.component';
import { WomenComponent } from './women/women.component';
import { TechnologyComponent } from './technology/technology.component';
import { QuoteComponent } from './quote/quote.component';
import { MyLettersComponent } from './my-letters/my-letters.component';
import { MyLetterComponent } from './my-letter/my-letter.component';
import { InMediaComponent } from './in-media/in-media.component';
import { GalleryComponent } from './gallery/gallery.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'My-Journey-to-Entrepreneurship', component: JourneyComponent },
  { path: 'How-Technology-Can-Make-Life-Simpler', component: TechnologyComponent },
  { path: 'Change-in-Air-Rides-to-Passengers-Rights', component: AirComponent },
  { path: 'What-would-you-accept-Choice-Need-or-Force', component: WomenComponent },
  { path: 'quote', component: QuoteComponent },
  { path: 'my-letter', component: MyLetterComponent },
  { path: 'in-media', component: InMediaComponent },
  { path: 'gallery', component: GalleryComponent }

];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    JourneyComponent,
    AirComponent,
    WomenComponent,
    TechnologyComponent,
    QuoteComponent,
    MyLettersComponent,
    MyLetterComponent,
    InMediaComponent,
    GalleryComponent
  ],
  imports: [
  RouterModule.forRoot(
      appRoutes,
    ),
    BrowserModule,
    AppRoutingModule,
	HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
