import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WordpressService } from '../wordpress.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

posts$: Observable<any[]>;


	
	constructor(private wp: WordpressService) {
    this.posts$ = this.wp.getPosts();
	
	console.log(this.posts$);
}

  ngOnInit() {
  }

}
